<template>
    <div>
        <AppbarFixed :hasBackButton="true" title="New Project"/>
        <CreateProjectForm/>
        <div class="bottom-margin-box"></div>
        <BottomNavBar :currentIndex="4" @updateIndex="updateIndex"/>
    </div>
</template>

<script>
import AppbarFixed from '../../components/general/AppbarFixed.vue';
import CreateProjectForm from '../../components/create_project/CreateProjectForm.vue';
import BottomNavBar from '../../components/general/BottomNavBar.vue';

export default {
    components: {
        AppbarFixed,
        CreateProjectForm,
        BottomNavBar,
    },
    methods: {
        updateIndex(index) {
            this.$store.commit("updateTabIndex", index);
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>

</style>