<template>
    <div class="mt-3">
        <h1>Create New Project</h1>
        <b-container>
            <b-form @submit.prevent="onSubmit">
                <b-form-group id="title-input" class="mb-4 px-2">
                    <div class="title-label-row">
                        <label for="input-1" class="form-label text-start">Project Title<span style="color:red"> *</span></label>

                        <span>{{titleLength}}/{{titleMax}}</span>
                    </div>
                    <b-form-input
                        id="input-1"
                        v-model="title"
                        type="text"
                        placeholder="Project Title"
                        size="lg"
                        autocomplete="off"
                        aria-describedby="input-1-live-feedback"
                    >
                    </b-form-input>
                </b-form-group>
                <b-form-group id="description-input" class="mb-2 px-2">
                    <div class="title-label-row">
                        <label for="description" class="form-label text-start">Project Description<span style="color:red"> *</span></label>

                        <span>{{descriptionLength}}/{{descriptionMax}}</span>
                    </div>
                    <b-form-textarea
                        id="description"
                        size="lg"
                        v-model="description"
                        placeholder="Description"
                        rows="3"
                        max-rows="6"
                        no-resize
                        autocomplete="off"
                        ></b-form-textarea>
                </b-form-group>
                <div class="w-100 px-2 py-2">
                    <div class="row">
                        <div class="col-9 text-start">
                            <label for="buyMyProject" class="form-label text-start">Enable <strong>Buy</strong> Button</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-switch d-flex flex-row-reverse">
                                <input class="form-check-input project-switch" type="checkbox" id="buyMyProjectOn" @click="updateBuyMy">
                            </div>
                        </div>
                    </div>
                </div>
                <b-form-group v-if="buyProjectOn == true" id="buy-my-input" class="mb-4 px-2">
                    <b-form-input
                        id="buy-my"
                        v-model="form.purchaseLink"
                        type="url"
                        placeholder="Enter a link to your store"
                        size="lg"
                        autocomplete="off"
                        aria-describedby="input-1-live-feedback"
                    >
                    </b-form-input>
                </b-form-group>
                <hr v-if="buyProjectOn == true">
                <div class="w-100 px-2 py-2">
                    <div class="row">
                        <div class="col-9 text-start">
                            <label for="buyMyProject" class="form-label text-start">Enable <strong>How-To</strong> Button</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-switch d-flex flex-row-reverse">
                                <input class="form-check-input project-switch" type="checkbox" id="buyMyProjectOn" @click="updateHowTo">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="useHowTo == true">
                    <b-form-group id="buy-my-input" class="mb-4 px-2">
                        <div class="title-label-row">
                            <label for="howToDescription" class="form-label text-start">Description</label>

                            <!-- <span>{{this.howToDescription.length}}/{{descriptionMax}}</span> -->
                        </div>
                        <b-form-textarea
                            id="howToDescription"
                            size="lg"
                            v-model="howToDescription"
                            placeholder="Describe your process"
                            rows="3"
                            max-rows="6"
                            no-resize
                            autocomplete="off"
                            ></b-form-textarea>
                    </b-form-group>
                    <p>or</p>
                    <b-form-group id="buy-my-input" class="mb-4 px-2">
                        <div class="title-label-row">
                            <label for="howToDescription" class="form-label text-start">Upload PDFs or Images</label>
                        </div>
                        <input type="file" class="form-control" id="howToMedia" @change="howToUpload($event.target.files)" multiple accept="image/* image/pdf">
                    </b-form-group>
                    <hr>
                </div>
                <div class="w-100 px-2">
                    <b-row class="pt-2">
                        <label for="mainImage" class="form-label text-start">Cover Image<span style="color:red"> *</span></label>
                    </b-row>
                    <input type="file" class="form-control" id="mainImage" @change="filesChange($event.target.files, true)" accept="image/png, image/gif, image/jpeg">
                    <label for="mainImage" class="sub-label text-start">This is the image people will see on the main feed</label>
                </div>
                <div class="w-100 px-2">
                    <b-row class="pt-2">
                        <label for="otherMedia" class="form-label text-start">Upload Content<span style="color:red"> *</span></label>
                    </b-row>
                    <input type="file" class="form-control" id="otherMedia" multiple @change="filesChange($event.target.files, false)">
                    <!-- <label for="otherMedia" class="sub-label text-start">This can be as many photos or videos as needed</label> -->
                </div>
                <!---
                <b-form-group
                    class="mx-2"
                >
                <b-row class="pt-2">
                    <label for="tool-input" class="form-label text-start">Tools used to make:</label>
                </b-row>
                <div class="d-flex w-100 justify-content-between align-items-center">
                    <b-form-input
                        id="tool-input"
                        v-model="toolInput"
                        type="text"
                        placeholder="Separate with commas"
                        size="lg"
                        aria-describedby="input-1-live-feedback"
                        :formatter="onEdit"
                        autocomplete="off"
                    >
                    </b-form-input>

                    <h3 @click="enterTool"><b-badge variant="light" class="shadow-sm parsillBlueBg mx-2 mt-2">Enter</b-badge></h3>
                </div>
                </b-form-group>
                <b-container v-for="(row, index) in toolsChunked" v-bind:key="index" class="px-2 pt-3">
                    <b-row>
                        <b-col @click="removeTool(row[0])" v-if="row.length > 0"><h3>{{row[0]}}</h3></b-col>
                        <b-col @click="removeTool(row[1])" v-if="row.length > 1"><h3>{{row[1]}}</h3></b-col>
                        <b-col @click="removeTool(row[2])" v-if="row.length > 2"><h3>{{row[2]}}</h3></b-col>
-->
                        <!-- for when there isn't a full row but you want the alignment -->
                        <!--
                        <b-col v-if="row.length < 2"></b-col>
                        <b-col v-if="row.length < 3"></b-col>
                    </b-row>
                    
                </b-container>
                <div v-if="form.toolsUsed.length > 0" class="w-100 px-2 py-2">
                    <div class="row">
                        <div class="col-9 text-start">
                            <label for="buyMyProject" class="form-label text-start">Use Links for Tools Used</label>
                        </div>
                        <div class="col">
                            <div class="form-check form-switch d-flex flex-row-reverse">
                                <input class="form-check-input" type="checkbox" id="buyMyProjectOn" @click="updateUseLinks">
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="useLinksOn==true">
                    <div v-for="(tool, index) in form.toolsUsed" :key="index">
                        <h5 class="text-start mx-3 mt-3">{{tool}}</h5>
                        <b-form-group id="tool-link-input" class="mb-1 px-2">
                        <b-form-input
                            :id="toolLinkIdentifier(tool, index)"
                            type="url"
                            placeholder="Enter a link to the tool"
                            size="lg"
                            autocomplete="off"
                            aria-describedby="input-1-live-feedback"
                        >
                        </b-form-input>
                    </b-form-group>
                    </div>
                </div>
                <hr v-if="useLinksOn==true"> -->
                <div class="w-100 px-2 pt-1">
                    <b-row class="pt-2">
                        <label for="categorySelect" class="form-label text-start">Category<span style="color:red"> *</span></label>
                    </b-row>
                    <select class="form-select form-control form-control-lg" id="categorySelect" @change="updateCategory">
                        <option v-for="category in categories" :key="category.id" :value="category.id">{{category.title}}</option>
                    </select>
                </div>
                <b-spinner v-if="loading==true" class="mt-5 mb-3"></b-spinner>
                <b-button v-else class="mt-5 mb-3" variant="primary" size="lg" @click="createProject">Create Project</b-button>
            </b-form>
            
            
        </b-container>
    </div>
</template>

<script>
import { db, storage } from '../../db';
import { mapState } from 'vuex';

export default {
    data() {
        return {
            title: null,
            description: null,
            howToDescription: "",
            howToFiles: [],
            form: {
                title: null,
                description: null,
                purchaseLink: null,
                toolsUsed: [],
                mainImage: null,
                otherMedia: [],
                category: null,
            },
            toolInput: "",
            buyProjectOn: false,
            useHowTo: false,
            useLinksOn: false,
            loading: false,
            titleMax: 30,
            descriptionMax: 100,
        }
    },   
    watch: {
        title(newTitle, oldTitle) {
            if (newTitle.length > this.titleMax) {
                
                this.$nextTick(() => {
                    this.title = oldTitle;
                })
                
            }
            else {
                this.form.title = newTitle;
            }
        },
        description(newDescription, oldDescription) {
            if (newDescription.length > this.descriptionMax) {
                
                this.$nextTick(() => {
                    this.description = oldDescription;
                })
                
            }
            else {
                this.form.description = newDescription;
            }
        },
    },
    mounted() {
        this.form.category = this.categories[0];
    },
    methods: {
        removeTool(tool) {
            const shouldDelete = confirm(`Delete ${tool} from tools?`);

            if (!shouldDelete) return;

            const index = this.form.toolsUsed.indexOf(tool);
            if (index > -1) {
                this.form.toolsUsed.splice(index, 1);
            }

            if (this.form.toolsUsed.length == 0) {
                this.useLinksOn = false;
            }
        },
        updateBuyMy() {
            this.buyProjectOn = !this.buyProjectOn;
        },
        updateHowTo() {
            this.useHowTo = !this.useHowTo;
        },
        updateUseLinks() {
            this.useLinksOn = !this.useLinksOn;
        },
        updateCategory() {
            var selectedId = document.getElementById("categorySelect").value;
            var relevantCategory = this.categories.filter(category => category.id == selectedId)[0];
            this.form.category = relevantCategory;
        },
        // testME() {
        //     for (var i=0; i < this.form.toolsUsed.length; i++) {
        //         var tool = this.form.toolsUsed[i]
        //         var linkField = document.getElementById(this.toolLinkIdentifier(tool, i));
        //         console.log(`${tool} link = ${linkField.value}`);
        //     }
        // },
        toolLinkIdentifier(tool, index) {
            return `${tool}${index}`;
        },
        toolLink(tool, index) {
            var linkField = document.getElementById(this.toolLinkIdentifier(tool, index));
            return linkField?.value;
        },
        async createProject() {
            var validationMessages = [];
            if (this.form.title == null) validationMessages.push("No Title");
            if (this.form.description == null) validationMessages.push("No Description");
            if (this.buyProjectOn == true && this.form.purchaseLink == null) validationMessages.push("No Purchase Link");
            if (this.useHowTo == true && (this.howToDescription == "" && this.howToFiles == [])) validationMessages.push("No How-To Content");
            if (this.form.mainImage == null || !((this.form.mainImage?.type.includes("image")) ?? false)) validationMessages.push("No Valid Cover Image");
            if (this.form.otherMedia.length == 0) validationMessages.push("No Valid Media Content");
            if (this.form.category == null) validationMessages.push("No Category");

            if (validationMessages.length > 0) {
                alert("Please fix the following errors:\n" + validationMessages.join("\n"));
                return;
            }

            // all good, lets upload

            this.loading = true;


            var media = [];
            for (var i=0; i < this.form.otherMedia.length; i++) {
                // const f = this.form.otherMedia[i];
                // if (f.type.includes("image") && !foundCoverImage) {
                //     foundCoverImage = true;
                //     this.form.mainImage = f;
                //     continue;
                // }
                media.push("media" + i.toString());
            }
            
            let categoryId = this.form.category.id;

            var data = {
                title: this.form.title.trim(),
                creatorId: this.parsillUser.id,
                creator: this.parsillUser.username,
                media: media,
                // toolsUsed: this.form.toolsUsed,
                // toolLinks: toolLinks,
                useLinksOn: this.useLinksOn,
                comments: [],
                created: new Date(),
                categoryId: categoryId,
                description: this.form.description,
                buyProjectOn: this.buyProjectOn,
                purchaseLink: this.form.purchaseLink,
                boosts: 0,
                
            };

            if (this.howToDescription != "") {
                data.howToDescription = this.howToDescription;
            }
            if (this.howToFiles.length > 0) {
                data.hasHowToImage = "howTo";
                data.howToLength = this.howToFiles.length;
            }

            try {
                const ref = await db.collection("projects").add(data);
                var projectId = ref.id;
                // now use that id to create the storage refs
                var projectRef = storage.ref().child("projects/" + projectId);
                const mainImgRef = projectRef.child("/main");
                
                mainImgRef.put(this.form.mainImage).then(() => {
                    console.log("uploaded Cover Image");
                    this.$router.go(-1);
                    // upload any other media
                    for (var i=0; i < this.form.otherMedia.length; i++) {
                        let name = media[i];
                        let file = this.form.otherMedia[i];
                        projectRef.child("/other/"+name).put(file).catch((err) => alert("Something went wrong uploading the project: " + `${err}`));
                        
                    }
                    
                }).catch(err => {
                    alert("Error uploading Cover Image: " + `${err}`);
                    this.loading = false;
                    return;
                });

                if (this.howToFiles.length > 0) {
                    for (let i=0; i < this.howToFiles.length; i++) {
                        const f = this.howToFiles[i];
                        const howToRef = projectRef.child(`/howTo/${i}`);
                        try {
                            howToRef.put(f)
                            .then(() => {
                                console.log(`uploaded How To Image ${i}`);
                            })
                            
                        }
                        catch (err) {
                            console.log(err);
                            alert("Error uploading how to media");
                            this.loading = false;
                            return;
                        }
                    }
                }

            }
            catch (err) {
                console.log(err);
                // alert("Error creating project.");
                alert(err);
                this.loading = false;
            }
        },
        onEdit(value) {
            // returned value is what goes into the form field

            if (value.includes(",")) {
                // Add to list and clear
                var formattedValue = value.replace(",", "");
                this.form.toolsUsed.push(formattedValue);
                return "";
            }

            return value;
        },
        enterTool() {
            if (this.toolInput == "" || this.toolInput == null) return;

            this.form.toolsUsed.push(this.toolInput.trim());
            this.toolInput = "";
        },
        filesChange(files, isMain) {
            if (isMain == true) {
                if (files.length > 0) {
                    this.form.mainImage = files[0];
                }
            } else {
                if (files.length > 0) {
                    this.form.otherMedia = files;
                }
            }
        },
        howToUpload(files) {
            if (files.length > 0) {
                for (let i=0; i < files.length; i++) {
                    const f = files[i];
                    if (f.type.includes("video")) {
                        alert("How-to files must be images or PDFs");
                        this.$nextTick(() => {
                            document.getElementById("howToMedia").value = null;
                        });
                        return;
                    }
                    
                }
                this.howToFiles = files;
            }
        }
    },
    computed: {
        titleLength() {
            return this.title?.length ?? 0;
        },
        descriptionLength() {
            return this.description?.length ?? 0;
        },
        toolsChunked: function () {
            var chunks = [];

            var rowIndex = 0;
            for (var i=0; i < this.form.toolsUsed.length; i++) {
                if (rowIndex == 0) {
                    chunks.push([]);
                }

                var tool = this.form.toolsUsed[i]
                chunks[chunks.length - 1].push(tool);

                if (rowIndex == 2) {
                    rowIndex = 0;
                } else {
                    rowIndex++;
                }
            }
            return chunks;
        },
        
        ...mapState({
            parsillUser: (state) => state.parsillUser,
            categories: (state) => state.categories,
        })
    },
}
</script>

<style scoped>
    .btn-primary {
        background-color: #09256e !important;
    }

    .form-label {
        font-size: larger;
    }

    .sub-label {
        font-size: medium;
    }

    .project-switch {
        width: 50px;
        height: 25px;
    }

    .title-label-row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

</style>